<template>
    <div class="board-container">
        <v-simple-table>
            <thead>
                <tr>
                    <th style="width:7%">번호</th>
                    <th style="width:10%">카테고리</th>
                    <th style="width:66%">제목</th>
                    <!-- <th style="width:10%">이름</th> -->
                    <th style="width:10%">작성일</th>
                    <th style="width:7%">조회</th>
                </tr>
            </thead>
            <tbody>
                <!-- <tr v-for="(notice, index) in notices" :key="`notice-${index}`" @click="showBoard(notice)" style="cursor:pointer; background-color:yellow">
                                <td>공지</td>
                                <td><div class="ellipsis">{{ notice.subject }}</div></td>
                                <td>{{ notice.writer.name }}</td>
                                <td>{{ notice.createdAt.toDate() }}</td>
                                <td>{{ notice.viewCount }}</td>
                            </tr>
                            <tr v-for="(board, index) in boards" :key="`board-${index}`" @click="showBoard(board)" style="cursor:pointer;">
                                <td>{{ summary.totalCount - skip - index }}</td>
                                <td><div class="ellipsis">{{ board.subject }}</div></td>
                                <td>{{ board.writer.name }}</td>
                                <td>{{ board.createdAt.toDate() }}</td>
                                <td>{{ board.viewCount }}</td>
                            </tr> -->
                <tr v-for="(board, index) in boards" :key="board._id" @click="$emit('click', board)">
                    <td>{{ summary.totalCount - (page - 1 ) * limit - index }}</td>
                    <td>{{ board.category }}</td>
                    <td><div class="ellipsis">{{ board.subject }}</div></td>
                    <td>{{ board.createdAt.toDateTime() }}</td>
                    <td>{{ board.viewCount }}</td>
                </tr>
            </tbody>
        </v-simple-table>

        <slot name="pagination"></slot>
    </div>
</template>

<script>
export default {
    props: {
        summary: { type: Object, default(){ return { totalCount: 0 } }},
        boards: { type: Array, default(){ return []; } },
        page: Number,
        limit: Number
    }
}
</script>